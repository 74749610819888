import React from 'react';

import Button from '@elements/Button';
import { useResize } from '@hooks';
import CaretLeft from '@svg/icons/caret-left.svg';
import CaretRight from '@svg/icons/caret-right.svg';
import {
  getPageList,
  changeUrlPath,
  getClasses,
  isSmScreenSize,
} from '@utils';

import './style.css';

type PaginationType = {
  paginationId: string,
  currentPage: number,
  totalPage: number,
  mainLink?: string,
  isRanking?: boolean,
  handleClick?: ({ numPage }) => void,
};

const Pagination = ({
  paginationId,
  currentPage,
  totalPage,
  mainLink,
  isRanking = false,
  handleClick,
}: PaginationType) => {

  const resizeFn = (width: number) => {
    const screenSm = isSmScreenSize(width);
    const initPageList = getPageList(currentPage, totalPage, screenSm, isRanking);
    return initPageList;
  };
  const [ pageList ] = useResize(currentPage, [], resizeFn);

  const mainClasses = getClasses(
    `pagination--main`,
    isRanking && 'pagination--ranking',
  );

  return (
    <nav className={mainClasses}>
      <ul className="pagination--container">
        {
          pageList.map( (page, index) => {
            const key = `btn-${paginationId}-page-${index}`;

            if(page === '...'){
              return (
                <li key={key}>
                  <div>
                    {page}
                  </div>
                </li>
              )
            }else{
              const numCurrentPage = Number(currentPage);
              let numPage = Number(page);
              let content;
              let classes = "";
              let ariaLabel;
              let href = mainLink;

              if(isNaN(page)){
                if( page === "l" ){
                  classes += "pagination--page-left";
                  ariaLabel = "icon-left";
                  content = <CaretLeft />;
                  numPage = numCurrentPage - 1;
                  if( numCurrentPage > 2 )
                    href += changeUrlPath(isRanking, numPage);
                }
                if( page === "r" ){
                  classes += "pagination--page-right";
                  ariaLabel = "icon-right";
                  content = <CaretRight />;
                  numPage = numCurrentPage + 1;
                  if( totalPage > numCurrentPage )
                    href += changeUrlPath(isRanking, numPage);
                }
              }else{
                content = page;
                if(numPage > 1)
                  href += changeUrlPath(isRanking, numPage);
              }
              href += isRanking ? "#ranking-list" : "";
              classes += getClasses(numPage === numCurrentPage && 'pagination--page-selected');
              return (
                <li key={key}>
                  <Button
                    ariaLabel={ariaLabel}
                    childClassName={classes}
                    href={!handleClick && href}
                    onClick={() => handleClick && handleClick({ numPage })}
                  >
                    {content}
                  </Button>
                </li>
              )
            }                
          })
        }
      </ul>
    </nav>
  )
};

export default Pagination;
