import React, { useState, useEffect } from 'react';

import Button from '@elements/Button';
import CompanyItem from '@elements/CompanyItem';
import Dropdown from '@elements/Dropdown';
import Pagination from '@elements/Pagination';
import Hero from '@sections/Hero';
import {
  getCompaniesList,
  getNumberPageFrLocation,
  getDropDownListfrRankings,
  getHeroInfo,
} from '@utils';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import '../styles/templates/rankingList.css';

type CompanyIdList = number[];

type CompanyProps = {
  contentful_id: string,
  companyId: number,
  position: number,
};

type ContentfulCompanyProps = {
  edges: Array<{
    node: CompanyProps,
  }>
};

type RankingProps = {
  contentful_id: string,
  displayName: string,
  slug: string,
  year: number,
  description: {
    description: string
  },
  reportLink: {
    label: string,
    url: string,
  },
  companyRankings: CompanyProps[],
  scoreImageMain: {
    title: string,
    gatsbyImageData: IGatsbyImageData,
  },
};

type ListRankingProps = {
  contentful_id: string,
  mainSlug: string,
  rankings: RankingProps[],
  mainTitle: string,
  listTitle: string,
  activeRanking: {
    contentful_id: string,
  },
  rankingImage: {
    title: string,
    gatsbyImageData: IGatsbyImageData,
  },
  useFilterHeroRanking: boolean,
};

type RankingListProps = {
  pageContext: {
    companyIdList: CompanyIdList[],
    rankingSlug: string,
    numPages: number,
  },
  data: {
    companies: ContentfulCompanyProps,
    ranking: RankingProps,
    rankingList: ListRankingProps, 
  },
  location: {
    search: string,
  },
};

const RankingList = ({
  location,
  pageContext: {
    companyIdList,
    rankingSlug,
    numPages,
  },
  data: {
    ranking,
    rankingList, 
    companies,
  },
}: RankingListProps): JSX.Element => {
  const {
    rankingImage,
    activeRanking,
    rankings,
    mainSlug,
    useFilterHeroRanking,
    mainTitle,
    listTitle,
  } = rankingList;
  const {
    displayName,
    description,
    reportLink,
    contentful_id: rankingId,
    year,
    scoreImageMain,
  } = ranking;

  const [currentPage, setcurrentPage] = useState();

  useEffect(() => {
    const numberPage = getNumberPageFrLocation(location);
    setcurrentPage(numberPage);
  }, [location]);

  const preDescription = description?.description?.split('/');

  const heroInfo = getHeroInfo(rankingImage, undefined, mainTitle, displayName, useFilterHeroRanking);
  const scoreImage = getImage(scoreImageMain?.gatsbyImageData);
  const dropDownList = getDropDownListfrRankings(rankings, activeRanking, mainSlug, year);
  const companiesList = getCompaniesList(currentPage, companyIdList, ranking, activeRanking, companies);
  const dropDownText = activeRanking.contentful_id === rankingId 
    ? "Previous Years"
    : "Years";
  const listText = activeRanking.contentful_id === rankingId 
    ? `The ${listTitle}`
    : `The ${year} ${listTitle}`;

  return (
    <>
      <section className="ranking-list__hero">
        <Hero
          extra={heroInfo.extra}
          item={heroInfo.item}
        />
      </section>
      <section className="ranking-list__description">
        <div className={mainSlug.startsWith('rankings-esg') ? "ranking-esg-list__top" : "ranking-list__top"}>
          {
            mainSlug.startsWith('rankings-esg') ? 
            <div className='description' >
              { preDescription[0] ? <p>{preDescription[0]}</p> : null }
              { preDescription[1] ? <p>{preDescription[1]}</p> : null }
              { preDescription[2] ? <p>{preDescription[2]}</p> : null }
            </div> :
            <p>
              { preDescription[0] ? <p className='description-rank'>{preDescription[0]}</p> : null }
              { preDescription[1] ? <p>{preDescription[1]}</p> : null }
            </p> 
          }
          <figure>
            <GatsbyImage alt={scoreImageMain?.title} image={scoreImage} />
          </figure>
        </div>
        <div className="ranking-list__btn-group">
          {
            reportLink && (
              <div className="btn--large">
                <Button
                  childClassName="btn--green-white"
                  href={reportLink.url}
                >
                  {reportLink.label}
                </Button>
              </div>
            )
          }
          {
            dropDownList?.length > 0 && (
              <Dropdown
                childClassName="ranking-list__dropdown"
                listChilds={dropDownList}
              >
                {dropDownText}
              </Dropdown>
            )
          }
        </div>
      </section>
      <section className="ranking-list__companies" id="ranking-list" >
        <div className="ranking-list__companies-title">
          <hr/>
          <h2>{listText}</h2>
        </div>
        <ul>
          {
            companiesList.map( company => (
              <CompanyItem
                key={company?.contentful_id}
                item={company}
                mainSlug={mainSlug}
              />
            ))
          }
        </ul>
        <Pagination
          currentPage={currentPage}
          isRanking
          mainLink={rankingSlug}
          paginationId={rankingId}
          totalPage={numPages}
        />
      </section>
    </>
  )
};

export default RankingList;

export const queryRankingList = graphql`
  query QUERY_RANKING_LIST($rankingListId: String!, $rankingId: String!) {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }

    seo: contentfulRanking(contentful_id: { eq: $rankingId }) {
      ...RankingSeo
    }
    rankingList: contentfulRankingList(contentful_id: { eq: $rankingListId }) {
      ...RankingListFull
    }
    ranking: contentfulRanking(contentful_id: { eq: $rankingId }) {
      ...RankingFull
    }
    companies: allContentfulCompany {
      edges {
        node {
          ...Company
        }
      }
    }
  }
`;