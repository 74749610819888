import React, { useState } from 'react';

import Button from '@elements/Button';
import LogoDown from '@svg/icons/arrow-down.svg';
import { Link } from '@elements/Link';

import './style.css';

type PageProps = {
  __typename: string,
  contentful_id: string,
  title: string,
  slug: string,
};

type DropdownProps = {
  children?: React.ReactNode,
  childClassName?: string,
  slug?: string,
  listChilds?: Array<PageProps>,
  onClick?: (ev) => void,
};

const Dropdown = ({
  children,
  childClassName = '',
  slug,
  listChilds,
  onClick,
}: DropdownProps): JSX.Element => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [mouseLeaved, setMouseLeaved] = useState(true);

  const href = !listChilds && slug && `/${slug}/`;

  return (
    <div
      className={`dropdown ${childClassName}`}
      onBlur={() => mouseLeaved && setShowPopUp(false)}
    >
      <Button
        childClassName="dropdown__button"
        href={href}
        onClick={() => setShowPopUp(!showPopUp)}
      >
        <span>{children}</span>
        {!href && <LogoDown />}
      </Button>
      {
        !href && (
          <ul
            className={`dropdown__popup dropdown__${showPopUp ? 'show' : ''}`}
            onMouseEnter={() => setMouseLeaved(false)}
            onMouseLeave={() => setMouseLeaved(true)}
          >
            {listChilds?.map((child) => {
              const key = child?.contentful_id;
              const Tag = child?.slug ? Link : 'button';
              if(!child?.contentful_id) return <React.Fragment key={key} />
  
              return (
                <li
                  key={key}
                  aria-hidden="true"
                  onClick={() => setShowPopUp(false)}
                >
                  <Tag
                    className="dropdown__item"
                    to={`/${child.slug}/`}
                    onClick={onClick}
                  >
                    {child.title}
                  </Tag>
                </li>
              )
            })}
          </ul>
        )
      }
    </div>
  );
};

export default Dropdown;
