import React from 'react';

import Button from '../Button';

import './style.css';

type CardHeaderProps = {
  childClassName?: string,
  topTextBold: string,
  topText: string,
  useHr: boolean,
  children?: React.ReactNode,
  buttonText?: string,
  buttonLink?: string,
};


const CardHeader = ({
  childClassName = '',
  topTextBold,
  topText,
  useHr,
  children,
  buttonText,
  buttonLink,
}: CardHeaderProps): JSX.Element => (
  <div className={`card--header ${childClassName}`}>
    <div className="card--header__top">
      {
        topTextBold && (
          <strong className="card--header__top__bold">
            { topTextBold }
          </strong>
        )
      }
      {
        topText && (
          <span className="card--header__top__normal">
            { topText }
          </span>
        )
      }
      {
        useHr && <hr/>
      }
    </div>
    <div className="card--header__inner">
      { children }
    </div>
    <div className="card--header__bottom">
      {
        buttonText && buttonLink && (
          <div className="btn--large">
            <Button
              childClassName="btn--green-white"
              href={ buttonLink }
            >
              { buttonText }
            </Button>
          </div>
        )
      }
    </div>
  </div>
);

export default CardHeader;
