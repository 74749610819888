import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

type UseResizeFn = (
  deps: any,
  initialState: any,
  callback: (number)=> any,
) => [Dispatch<SetStateAction<any>>];

// eslint-disable-next-line import/prefer-default-export
export const useResize: UseResizeFn = (
  deps,
  initialState,
  callback= ()=>{},
) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const newValueState = callback(window.innerWidth);
    setState(newValueState);
    window.addEventListener('resize', ({ target })=>{
      const newValue = callback(target.innerWidth);
      setState(newValue);
    });
  }, [deps])

  return [ state ];
};
