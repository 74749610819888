import React from 'react';

import CardHeader from '@elements/CardHeader';
import RichText from '@elements/RichText';
import { getClasses } from '@utils';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import './style.css';

type ButtonCTAProps = {
  __typename: string,
  contentful_id: string,
  buttonText: string,
  buttonLink: string,
  color: string,
  position: string,
}

type HeroProps = {
  item: {
    title: string,
    classes: string,
    textSide: string,
    background: string,
    text: {
      raw: string,
      references: ButtonCTAProps | any,
    },
    image: {
      title: string;
      gatsbyImageData: IGatsbyImageData,
    },
    imageShow: boolean,
    useFilter: boolean,
  },
  extra: any,
};

const Hero = ({ 
  item,
  extra = {},
}: HeroProps): JSX.Element => {
  const {
    title,
    text,
    image: preImage,
    imageShow,
    classes = '',
    textSide,
    background,
    useFilter,
  } = item;

  const longTitle = title?.split(' ')

  const { heroHeader } = extra;

  const image = getImage(preImage?.gatsbyImageData);
  const loading = background === `image` ? `eager` : 'lazy';

  const mainClasses = getClasses(
    `hero--main`,
    item?.image?.title?.includes('ESG Rankings') ? `is-bg-esg-${background}` : `is-bg-${background}`,
    classes,
    background !== `image` && imageShow && `hero--image-mobile`,
  );

  const containerClasses = getClasses(
    `hero__container`,
    textSide === 'left' && image && `flex-row-reverse`,
    textSide === 'right' && !image && `flex-row-reverse`,
    textSide === 'right' && `flex-row`,
    useFilter && 'hero__container--use-filter',
  );

  return (
    <div className={mainClasses}>
      <div className={containerClasses}>
        {
          image && (
            <section className="hero__image">
              <GatsbyImage
                alt={preImage.title}
                image={image}
                loading={loading}
              />
              {
                classes?.startsWith('api-hero-top') ? 
                  <div className='api-text-into-image'>
                    <span>{`${longTitle[0]} ${longTitle[1]} ${longTitle[2]} ${longTitle[3]} `}</span>
                    <span className='title-last-words'>{`${longTitle[4]} ${longTitle[5]}`}</span>
                  </div> :
                null
              }
            </section>
          )
        }
        {
          text && (
            <section className="hero__text">
              <div>
                <RichText bodyRichText={text} />
              </div>
            </section>
          )
        }
        {
          heroHeader && (
            <section className="hero__header">
              <div>
                <CardHeader
                  buttonLink={heroHeader.buttonSlug}
                  buttonText={heroHeader.buttonText}
                  childClassName="card--header--hero"
                  topText={heroHeader.date}
                  topTextBold={heroHeader.category}
                  useHr={heroHeader.useHr}
                >
                  <h1>{ heroHeader.title }</h1>
                  {
                    item?.image?.title?.includes('ESG Rankings') ? null : <p>{ heroHeader.subtitle }</p>
                  }
                  
                </CardHeader> 
              </div>
            </section>
          )
        }
      </div>
    </div>
  );
};

export default Hero;