import React from 'react';

import { getReputationScoreLabel } from '@utils';
import { Link } from '@elements/Link';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import './style.css';

type CompanyItemProps = {
  item: {
    displayName: string,
    score: number,
    position: number,
    slug: string,
    icon: {
      title: string,
      gatsbyImageData: IGatsbyImageData,
    },
  },
  mainSlug: string,
};

const CompanyItem = ({
  item: {
    displayName,
    score,
    position,
    icon,
    slug,
  },
  mainSlug,
}: CompanyItemProps): JSX.Element => {
  const image = getImage(icon?.gatsbyImageData);
  const scoreLabel = getReputationScoreLabel(score);
  const scoreFixed = Number(score).toFixed(1) || score;

  return (
    <li className="company-item--main">
      <Link to={`/${mainSlug}/company/${slug}/`} >
        <div className="company-item__inner">
          <span className="company-item__position">
            { position }.
          </span>
          <figure>
            {
              image && (
                <GatsbyImage alt={icon.title} image={image} />
              )
            }
          </figure>
          <h3>
            { displayName }
          </h3>
          <div className="company-item__score">
            <span>
              { scoreFixed }
            </span>
            <span className={`company-item__label--${scoreLabel}`}>
              { scoreLabel }
            </span>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default CompanyItem;